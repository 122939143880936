<template>
  <fcs-container fluid gutters class="d-flex alert-history flex-column">
    <div class="mt-3">
      <h2 class="title mt-3 mb-3"> {{ $t('navLinks.alerts') }}</h2>
      <fcs-row>
        <fcs-col class="mt-2" lg="3" xl="3"><search-field @change="onSearch" /></fcs-col>
        <fcs-col v-if="preProdOnly"><fcs-table-filter-bar v-bind="typeFilterConfig" @input="alertTypeChange" /></fcs-col>
        <fcs-col v-else><fcs-table-filter-bar v-bind="typeFilterConfigProd" @input="alertTypeChange" /></fcs-col>
      </fcs-row>
      <fcs-table
        :items="items"
        :fields="fields"
        :per-page="perPage"
        @sort-changed="sortTable"
        :no-local-sorting="true"
        id="alerts"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>{{ $t('global.loading') }} </strong>
          </div>
        </template>
        <template #cell(alert)="data">
          <span class="alert-name">{{data.item.alertName}}</span>
          <span class="alert-type">{{$i18n.t(mapAlertToTranslation[data.item.alertType])}}</span>
        </template>
        <template #cell(timestamp)="data">
          <span class="vehicle">{{ formatTime(data.item.timestamp) }}</span>
        </template>
        <template #cell(location)="data">
          <span class="start-location">{{ formatLocation(data.value.latitude, data.value.longitude)}}</span>
        </template>
      </fcs-table>
      <div class="d-flex justify-content-end mb-md-2">
        <span class="pagination-total-rows mr-3 mt-2">{{ $t('vehicleList.total') }}: {{ rows }}</span>
        <fcs-pagination-size-selector class="fcs-pagination-size-selector mr-2"
                                      :option-text="$t('global.perPage')"
                                      v-model="perPage" @input="onPaginationSizeChange"
        >
        </fcs-pagination-size-selector>
        <fcs-pagination class="ml-2 mt-1"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="rows"
            aria-controls="alerts"
            @change="loadPage"
        >
        </fcs-pagination>
      </div>
    </div>
  </fcs-container>
</template>
<script>
import { FcsTable, FcsTableFilterBar } from 'fcsui';
import SearchField from '@/components/common/SearchField';
import { i18n } from '@/i18n';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment-timezone';
import { ALERT_TYPE } from '@/constants';
import { isEqual, debounce } from 'lodash';

export default {
  name: 'Alerts',
  components: {
    FcsTable,
    FcsTableFilterBar,
    SearchField
  },
  data () {
    return {
      mapAlertToTranslation: {
        SECURITY: 'constants.alertType.security',
        INCONSISTENT_LOCATION: 'constants.alertType.inconsistentLocation'
      },
      preProdOnly: process.env.VUE_APP_HIDE_FEATURE === 'false',
      currentPage: 1,
      fields: [
        { key: 'alert', label: i18n.t('global.alertHistoryTable.alert') },
        { key: 'vin', label: i18n.t('global.alertHistoryTable.vehicle'), sortable: true },
        { key: 'timestamp', label: i18n.t('global.alertHistoryTable.timestamp'), sortable: true },
        { key: 'location', label: i18n.t('global.alertHistoryTable.location') }
      ],
      typeFilterConfigProd: {
        columnName: 'type',
        columnLabel: i18n.t('global.alertHistoryTable.alert'),
        allOptionsText: i18n.t('global.alertHistoryTable.allSelectedLabelAlert'),
        filterOptions: [
          {
            options: [
              {
                label: i18n.t('global.alertHistoryTable.filterItems.securityAlert'),
                value: ALERT_TYPE.security
              },
              {
                label: i18n.t('global.alertHistoryTable.filterItems.inconsistentLocationAlert'),
                value: ALERT_TYPE.inconsistentLocation
              }
            ]
          }
        ]
      },
      typeFilterConfig: {
        columnName: 'type',
        columnLabel: i18n.t('global.alertHistoryTable.alert'),
        allOptionsText: i18n.t('global.alertHistoryTable.allSelectedLabelAlert'),
        filterOptions: [
          {
            options: [
              {
                label: i18n.t('global.alertHistoryTable.filterItems.securityAlert'),
                value: ALERT_TYPE.security
              },
              {
                label: i18n.t('global.alertHistoryTable.filterItems.inconsistentLocationAlert'),
                value: ALERT_TYPE.inconsistentLocation
              },
              {
                label: i18n.t('global.alertHistoryTable.filterItems.inclineAlert'),
                value: ALERT_TYPE.incline
              },
              {
                label: i18n.t('global.alertHistoryTable.filterItems.impactAlert'),
                value: ALERT_TYPE.impact
              }
            ]
          }
        ]
      },
      selectedAlertTypes: []
    };
  },
  methods: {
    onAlertTypeChange (event) {
      const alertTypes = event.clearFilter ? [] : event.selectedFilterColumnOptions.map(opt => opt.value);

      if (!isEqual(alertTypes, this.selectedAlertTypes)) {
        this.selectedAlertTypes = alertTypes;
        this.setAlertHistorySelectedTypes(alertTypes);
        this.getAlertHistory();
      }
    },

    onSearch (query) {
      this.setAlertSearchKey(query);
      this.getAlertHistory();
    },

    async loadPage (pageNum) {
      this.setPageNum(pageNum - 1);
      await this.getAlertHistory();
    },

    async onPaginationSizeChange () {
      await this.getAlertHistory();
    },

    async sortTable (event) {
      const { sortBy, sortDesc } = event;
      const direction = sortDesc === false ? 'asc' : 'desc';
      this.setSortParam(`${sortBy},${direction}`);
      await this.getAlertHistory();
    },

    formatTime (val) {
      const timezone = moment.tz.guess();
      return moment.tz(val, timezone).format('YYYY-MM-DD hh:mm:ss A');
    },

    formatLocation (latitude, longitude) {
      if (latitude === 0.0 || longitude === 0.0 || !latitude || !longitude) {
        return i18n.t('location.unknown');
      }

      return `${latitude.toFixed(6)}, ${longitude.toFixed(6)}`;
    },
    ...mapMutations(['setScheduleStatus', 'setSortParam', 'setPageNum', 'setAlertHistorySelectedTypes', 'setAlertSearchKey']),
    ...mapActions(['getAlertHistory'])
  },
  computed: {
    ...mapGetters({ rows: 'getAlertRows', items: 'getAlerts', getPaginationSize: 'getPaginationSize', isBusy: 'getAlertsTableState' }),
    perPage: {
      async set (size) {
        this.$store.commit('setPaginationSize', size);
      },
      get () {
        return this.getPaginationSize;
      }
    }
  },
  created () {
    this.alertTypeChange = debounce(this.onAlertTypeChange, 1000);
  },
  async mounted () {
    await this.getAlertHistory();
    this.setScheduleStatus('');
  },
  beforeDestroy () {
    this.alertTypeChange.cancel();
  }
};
</script>

<style lang="scss" scoped>
.paginator {
  display: inline-flex;
}
.title{
  text-transform: uppercase;
  font-weight: normal;
  color: #002F6C;
  letter-spacing: 1.8px;
  font-size: 24px;
  height:36px;
}
.alert-name{
  font-weight: bold;
  display:block
}
.alert-type{
  color: #8A8A8A;
}
.alert-history{
  width: 90%;
  margin:auto;
}

</style>
